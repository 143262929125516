<template>
  <v-container fluid>
    <v-row class="pteste d-flex fillHeight pt5 topoMobile padMobile  justify-center " style=" 
            min-height: 550px; 
            padding: 50px 30px" align-center id="4pt">

      <v-col cols="12" md="10">
        <h1 class="textoTopo" style="font-size:100px;
            font-weight: 900;
            color:#fff;
            margin-top:280px;
            opacity:80%;
            line-height: 80px;
            padding:0px 0px">
          Trabalhe <br> Conosco</h1>
      </v-col>
    </v-row>

    <v-row style=" padding: 90px 0px; height: auto">
      <v-col cols="12" md="6" lg="6" class=" " style="padding: 12px 0px !important;" data-aos="fade-right"
        data-aos-duration="2500">
        <v-img class="disableMobile" src="../assets/pauloThiago.webp"
          style="border-radius: 0 20px 20px 0px; height: auto; "></v-img>
      </v-col>

      <v-col cols="12" md="6" lg="6" style="padding:30px 40px" class="align-center justify-center flex-column d-flex"
        data-aos="fade-up" data-aos-duration="2500">
        <div class="d-flex justify-center flex-column positionMobile" style="background-color:;
                 border-radius: 30px;
                 padding: 80px 50px;
                 opacity:;  
                 width: 500px;
                 height: 455px;">
          <h1
            style="font-family: sofia pro, Roboto; font-weight: 800; font-size: 27px; color: #D31F2C; line-height: 1; text-align: center; margin-bottom:20px;">
            Preencha o formulário para se candidatar à uma vaga na Hugle</h1>
          <v-text-field background-color="#fff" outlined v-model="contato.nome" label="Nome" required>
          </v-text-field>
          <v-text-field background-color="#fff" outlined v-model="contato.email" label="E-mail" required></v-text-field>
          <v-text-field v-mask="'(##) #####-####'" background-color="#fff" outlined v-model="contato.telefone"
            label="Telefone" required>
          </v-text-field>
          <v-text-field background-color="#fff" outlined v-model="contato.cargo" label="Cargo"
            required>
          </v-text-field>
          <v-text-field background-color="#fff" outlined v-model="contato.cidade" label="Cidade onde reside"
            required>
          </v-text-field>
          <v-text-field background-color="#fff" outlined v-model="contato.valores" label="Quais são os seus valores?"
            required>
          </v-text-field>
          
          <v-btn :disabled = "loading" @click="enviarFormulario" class="mr-4" type="button" height="50px" style="background-color: #1E3E87; color: #fff; 
            font-family: Sofia pro, Roboto; font-weight: 600; font-size:15px; border-radius: 30px;
            text-transform: inherit; margin-top: 10px; margin-right: 0px !important;"> Enviar

          </v-btn>
        </div>
      </v-col>
    </v-row>

     <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    loading: false,
    text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
    contato: { nome: "", email: "", telefone: "", cargo: "", cidade: "", valores:"" },
    timeout: 2000,
  }),

  methods: {

   
      enviarFormulario() {
            if ((this.contato.nome === "")) { 
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.email === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.telefone === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.cargo === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.cidade === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.valores === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else{
      this.loading = true,
      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id_formulario: 71, lead: this.contato }),
      })
        .then((response) => response.text())
        .then((data) => {
          window.console.log(data);
          this.snackbar = true;

        });
    }
  },
}
}
</script>

<style scoped>
@media (min-device-width: 340px) and (max-device-width: 731px) {

  .padMobile {
    padding: 50px 0px !important;

  }

  .positionMobile {
    position: relative !important;
    right: 0 !important;
    top: initial !important;
    padding: 50px 60px !important;
    width: 360px !important;
  }

  .topoMobile {
    background-image: url("../assets/trabalho.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(255, 255, 255, 0.596);
    background-position: center;
  }

  .textoTopo {
    font-size: 60px !important;
    font-weight: 900 !important;
    color: rgb(255, 255, 255) !important;
    margin-top: 190px !important;
    opacity: 0.5 !important;
    line-height: 59px !important;
    padding: 3px 3px !important;
  }
}

.pt5 {
  background-image: url("../assets/trabalheConosco.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: rgba(255, 255, 255, 0.596);
  background-position: center;
}
</style>