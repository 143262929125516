<template class="">
  <v-container fluid class="">
    <v-row justify="center" align="center">
    </v-row>
    <v-row v-if="intBreakPoint >= 2" align="center" style="margin-top:-26px">
      <v-col cols="12" md="12" style="margin:0px !important; padding:0px !important">
        <video-background :src="('https://abaregroup.com/media/video.mp4')" style="max-height: 650px; height: 650px;">
        </video-background>
      </v-col>
      <v-col cols="12" md="6" style="position:absolute">
        <h1 class="textoTopo" style="font-size:45px;
            font-weight: 900;
            color:#fff;
            z-index:9 !important;
            position:absolute;
            word-break: normal;
            padding-left: 80px !important;
            padding-bottom:0px !important;
            line-height: 1;">
          Do ZERO a uma conversa 100% em inglês em menos de 1 ano</h1>
      </v-col>
    </v-row>

    <v-row v-if="intBreakPoint < 2" class="bgMobile" style="margin-top: 12px">
      <v-col cols="12" md="6" class="pad">
        <h1 class="fontSzMobile " style="font-size:50px;
            font-weight: 900;
            color:#fff;
            z-index:9 !important;
            position:absolute;
            word-break: normal;
            padding-left: 12px;
            padding-top:320px !important;
            line-height: 1;">
          Do ZERO a uma conversa 100% em inglês em menos de 1 ano</h1>
      </v-col>
    </v-row>

    <v-row class="pad heightAutoMobile " style=" height:38vh; padding: 12px 60px;" align-center id="4pt">
      <v-col cols="12" md="8" data-aos="fade-up" data-aos-duration="1500" class="align-center justify-center">
        <h1 class="fontSzMobile pad0mb"
          style="font-family: Sofia pro, Roboto; font-size: 50px; font-weight: 950; line-height: 1; color: #D31F2C; padding-left:12px">
          ESPECIALISTAS EM INGLÊS <br>
          FLUENTES EM PESSOAS
        </h1>
        <p class="pad0mb"
          style="font-family: Sofia pro, Roboto; font-size:25px; color:#D31F2C; font-weight:400; padding:0px 12px;">
          Aulas
          100%
          em inglês desde o primeiro dia</p>
      </v-col>

      <v-col class="d-flex justify-center align-center flex-column padMobile" cols="12" md="4" style="margin-bottom:20px">
        <div class="d-flex justify-center flex-column positionMobile" style="background-color: #1e3e87d4; border-radius: 10px; padding: 50px 70px; opacity:; position: absolute; right:20px; top: 300px;
    width: 500px; height: 555px;" data-aos="fade-up" data-aos-duration="1500">
          <h1
            style="font-family: sofia pro, Roboto; font-weight: 500; font-size: 25px; color: #fff; line-height: 1; text-align: ; margin-bottom:20px;">
            Metodologia efetiva, ou o seu dinheiro de volta em 30 dias</h1>
          <v-text-field background-color="#fff" outlined v-model="contato.nome" label="Nome" required>
          </v-text-field>
          <v-text-field background-color="#fff" outlined v-model="contato.email" label="E-mail" required></v-text-field>
          <v-text-field background-color="#fff" v-mask="'(##) #####-####'" outlined v-model="contato.telefone"
            label="Whatsapp" required>
          </v-text-field>
          <v-select style="color: #fff; background-color: #fff; height: 55px;" :items="items" v-model="contato.unidade"
            label="Unidade mais próxima" outlined></v-select>
          <v-btn :disabled="loading" :load="loadButton" @click="enviarFormulario" class="mr-4" type="button" height="50px"
            style="background-color: #fff; color: #1E3E87; 
            font-family: Sofia pro, Roboto; font-weight: 600; font-size:15px; border-radius: 30px;
            text-transform: inherit; margin-top: 25px; margin-right: 0px !important;">
            Quero agendar agora
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row data-aos="fade-up" data-aos-duration="1800" style="margin: 0px" class="flex-column">
      <v-row
        style="background: linear-gradient(148deg, rgba(167,18,19,1) 0%, rgba(223,57,57,1) 100%, rgba(255,255,255,1) 100%); height: 35px;">
      </v-row>

      <v-row class="padMobile"
        style="margin-top: 0px; background: linear-gradient(148deg, rgb(0, 21, 28) 0%, rgb(4 79 171) 100%, rgb(255, 255, 255) 100%); padding: 20px 20px;">
        <v-col cols="12" md="12" class="d-flex  align-center flex-column">
          <h1 style="font-family: sofia pro, Roboto; font-weight: 500; font-size: 40px; color: #fff; line-height: 1;
    text-align: center;">Ser um Hugler é</h1>
        </v-col>
        <v-col cols="12" md="12">
          <v-row justify="" style="padding: 0px; align-items: start !important;">
            <v-col cols="12" md="3" class="d-flex align-center flex-column justify-center">
              <v-img width="190" src="../assets/calendario1.gif"></v-img>
              <p class="paddinPmb" style="
              padding-bottom: 0px !important;
              margin-bottom: 0px !important;
              padding-left:20px;
              font-family: sofia pro, Roboto;
              color: #fff;
              font-weight: 300;
              font-size: 18px;
              line-height: 1.2;
              text-align: center;
            ">Ter aula até 5x por semana na modalidade de curso intensivo.</p>
            </v-col>

            <v-col cols="12" md="3" class="d-flex align-center flex-column justify-center">
              <v-img width="140" style="margin-top:25px" src="../assets/live.gif"></v-img>
              <p style="
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        font-family: sofia pro, Roboto;
        color: #fff;
        font-weight: 300;
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
        margin-top: 25px;
        ">Ter aula 100% ao vivo mesmo nas turmas online.</p>
            </v-col>

            <v-col cols="12" md="3" class="d-flex align-center flex-column justify-center">
              <v-img width="190" src="../assets/paises.gif"></v-img>
              <p style="
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        font-family: sofia pro, Roboto;
        color: #fff;
        font-weight: 300;
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
        ">Professores com vivência internacional.</p>
            </v-col>

            <v-col cols="12" md="3" class="d-flex align-center flex-column justify-center">
              <v-img width="100" style="margin-top:50px" src="../assets/garantia.gif"></v-img>
              <p style="
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        padding-top:40px !important;
        font-family: sofia pro, Roboto;
        color: #fff;
        font-weight: 300;
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
        ">Garantia de aprendizado</p>
            </v-col>
          </v-row>
        </v-col>


      </v-row>

      <v-row
        style="background: linear-gradient(148deg, rgba(167,18,19,1) 0%, rgba(223,57,57,1) 100%, rgba(255,255,255,1) 100%); height: 25px;">
      </v-row>
    </v-row>

    <v-row data-aos="fade-up" data-aos-duration="1500" class="align-center justify-center d-flex">
      <v-col cols="12" md="12">
        <h1 class="fontSzMobile pad0" style="
        padding: 0px 20px;
        margin-top: 30px;
        font-family: Sofia pro, Roboto;
        font-size: 40px;
        padding-bottom:20px;
        line-height: 1;
        color:#1B3765;
        text-align: center;">Veja o que dizem da Hugle:</h1>
      </v-col>
      <!-----------------------------------------------------------------DESKTOPSON--------------------------------------------------------------------------------------------------------------------------------------->
      <v-col v-if="intBreakPoint > 2" cols="12" md="12" class="alinhadoM" style="max-height:350px; height:350px">
        <v-carousel hide-delimiters class="alinhadoM" style="border-radius:50px !important">
          <v-carousel-item v-for="(foto, i) in fotos" :key="i" :src="foto.src" contain
            style="border-radius: 50px !important; width: 800px">
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <!------------------------------------------------------------------MOBILHA----------------------------------------------------------------------------------------------------------------------------------------->
      <v-col v-if="intBreakPoint < 2" cols="12" md="12" class="alinhadoM" style="max-height:350px; height:350px">
        <v-carousel :show-arrows="false" cycle="5s" hide-delimiters class="alinhadoM"
          style="border-radius:50px !important">
          <v-carousel-item class="" v-for="(foto, i) in fotosMb" :key="i" :src="foto.src" contain
            style="border-radius: 50px !important; width: 350px">
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <!------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------>
    <v-row class=" justify-center align-center padMobile">
      <v-col cols="12" md="6" data-aos="fade-up" data-aos-duration="1500">
        <h1 class="fontSzMobile" style="
        padding: 30px 20px;
        margin-top: 0px;
        font-family: Sofia pro, Roboto;
        font-size: 40px;
        line-height: 1;
        color:#1B3765;
        text-align: center;">Trajetória do aluno</h1>
      </v-col>
    </v-row>

    <v-row class="justify-center align-center ">
      <v-col cols="12" md="12" data-aos="fade-up" data-aos-duration="1500">
        <div v-if="intBreakPoint > 2">
          <v-img width="100%" style="top: -50px;" class="disableMobile" src="../assets/mapa huggle.png"></v-img>
        </div>
        <div v-if="intBreakPoint < 2" style="padding-right: 0px !important">
          <v-img style="top:-32px" contain max-height="900px" src="../assets/mapaHugleVertical.png"> </v-img>
        </div>
      </v-col>
    </v-row>

    <v-row
      style="background: linear-gradient(148deg, rgb(0, 21, 28) 0%, rgb(4 79 171) 100%, rgb(255, 255, 255) 100%) !important; height:65px">
    </v-row>

    <v-row style="padding-bottom: 50px" data-aos="fade-up" data-aos-duration="1500"
      class="align-center justify-center d-flex">
      <v-col cols="12" md="5" class="align-center justify-center d-flex flex-column">
        <h1 class="fontSzMobile padGarantia" style="
        padding: 30px 0px 20px 110px;
        margin-top: 0px;
        font-family: Sofia pro, Roboto;
        font-size: 40px;
        line-height: 1;
        color:#1B3765;
       ">
          <span style="font-size:45px; ">G</span>arantia de aprendizado <span
            style="font-size:70px; color:#D31F2C">HUGLE!</span>
        </h1>
        <p class="padGarantia" style="font-family:Sofia pro; font-size:20px; padding-left:110px">
          Ao estudar na Hugle, você tem a garantia de aprendizado ou o direito de estudo vitalício na escola que se
          matriculou. <br><br>
          Simples assim! Se você passar pelo curso completo e não conseguir se comunicar de maneira satisfatória, nós
          garantimos aulas extras sem custo nenhum pelo tempo que for necessário para que você se comunique no novo
          idioma.<br><br>
          <span style="font-weight:800">*Consulte na sua unidade as condições necessárias para a garantia deste
            direito.</span>
        </p>
      </v-col>
      <v-col cols="12" md="6" class="align-center justify-center d-flex align-self-end">
        <v-img class="gifMobile" contain width=500px max-width="500px" src="../assets/gifEstudante.gif"></v-img>
      </v-col>
    </v-row>

    <v-row class="justify-center align-center d-flex flex-column" data-aos="fade-up" data-aos-duration="1500">
      <h1 data-aos="fade-up" data-aos-duration="1500" style="padding: 5px 20px;
       font-family: Sofia pro, Roboto;
       font-size: 40px;
       padding-bottom:10px;
       line-height:1;
       color:#1D3E88;
       text-align:center;">
        6 Razões para você escolher a
        <span style="color:#D31F2C">HUGLE</span>
      </h1>
      <p data-aos="fade-up" data-aos-duration="1500" style="font-family: Sofia pro, Roboto;
      font-size: 25px;
      color:#D31F2C;
      margin-bottom:-25px;
      text-align: center;
      font-weight:800 ;">
        Arraste para o lado para ver mais
      </p>


      <div v-if="intBreakPoint > 2">
        <flipbook class="flipbook" :pages="paginas" :zooms="zoom" :dragToFlip="true" v-slot:flipbook>
        </flipbook>
      </div>
      <div v-if="intBreakPoint < 2" data-aos="fade-up" data-aos-duration="1500">
        <flipbook class="flipbook flipMobile" :pages="paginaMobile" :zooms="zoom" :dragToFlip="true" v-slot:flipbook>
        </flipbook>
      </div>
    </v-row>

    <v-row class="visoesH">
      <v-col cols="12" md="6" class="align-center pdLeft justify-center d-flex flex-column" style="padding-left:140px">
        <h1 style="font-family: sofia pro, Roboto; font-weight: 500; font-size: 40px; color: #fff; line-height: 1; margin-bottom:20px
        ">Propósito, Visão e Valores </h1>
        <div class="d-flex flexMb">
          <v-card class="align-center justify-center d-flex flex-column" @click="proposito = true" style="
        cursor: pointer;
        max-width: 200px;
        max-height:200px;
        min-width: 200px;
        min-height:200px;
        background-color:#fff;
        border-radius:20px ">
            <v-icon style="color:#1B3765; font-size:100px">mdi-bullseye-arrow</v-icon>
            <p style="color:#1B3765; font-size:25px; font-weight:500px; font-family: Sofia Pro">Propósito</p>
            <v-btn @click="proposito = true"
              style="background-color:#D31F2C; color:#fff; text-align:center; font-weight:800; border-radius:20px">
              Clique aqui
            </v-btn>
          </v-card>
          <v-card class="align-center  marginCards justify-center d-flex flex-column" @click="visao = true" style="
        cursor: pointer;
        max-width: 200px;
        max-height:200px;
        min-width: 200px;
        min-height:200px;
        margin-left: 10px;
        margin-right: 10px;
        background-color:#fff;
        border-radius:20px ">
            <v-icon style="color:#1B3765; font-size:100px">mdi-eye-outline</v-icon>
            <p style="color:#1B3765; font-size:25px; font-weight:500px; font-family: Sofia Pro">Visão</p>
            <v-btn @click="visao = true"
              style="background-color:#D31F2C; color:#fff; text-align:center; font-weight:800; border-radius:20px">
              Clique aqui
            </v-btn>
          </v-card>
          <v-card class="align-center justify-center d-flex flex-column" @click="valores = true" style="
        cursor: pointer;
        max-width: 200px;
        max-height:200px;
        min-width: 200px;
        min-height:200px;
        background-color:#fff;
        border-radius:20px ">
            <v-icon style="color:#1B3765; font-size:100px">mdi-diamond-stone</v-icon>
            <p style="color:#1B3765; font-size:25px; font-weight:500px; font-family: Sofia Pro">Valores</p>
            <v-btn @click="valores = true"
              style="background-color:#D31F2C; color:#fff; text-align:center; font-weight:800; border-radius:20px">
              Clique aqui
            </v-btn>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="6" style="padding-bottom: 0px" class="align-center align-self-end">
        <v-img v-if="intBreakPoint > 2" contain style="max-height:450px" src="../assets/visoes.png"></v-img>
        <v-img v-if="intBreakPoint < 2" contain style="max-height:450px" src="../assets/visoesUp.png"></v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex align-center justify-center flex-column " cols="12" md="12" data-aos="fade-up"
        data-aos-duration="1500">
        <h1 class="fontTextoMeio"
          style="font-family: Sofia pro, Roboto; font-size: 40px; line-height: 1; color: #1D3E88; padding:20px 20px;  margin-bottom:28px; word-break: normal;">
          Viva a <span style="color:#D31F2C">experiência</span> Hugle em um minuto e meio! </h1>
        <video class="videoCorpo" style="width:850px" src="https://abaregroup.com/media/hugleEducation.mp4"
          controls="true" autoplay muted></video>
      </v-col>
    </v-row>

    <router-link to="sejaParceiro">
      <v-row data-aos="fade-up" data-aos-duration="1500" class="bgParceria" style="padding-top:40px; margin-top: 40px">
      </v-row>
    </router-link>

    <v-row class="padBlog " style="margin-top: 0px; padding: 20px 150px;">
      <v-col cols="12" md="12" class="d-flex align-center flex-column" style="
      margin-top:15px; padding-bottom:50px" data-aos="fade-up" data-aos-duration="2000">
        <h1 class="fontSzMobile" style="font-family: Sofia pro, Roboto;
          font-size: 40px;
          font-weight: 950;
          line-height: 1;
          color: #D31F2C;
          padding:0px 20px;
          text-align: center;">
          BLOG DA <span style="color: #1B3765;">HUGLE</span>
        </h1>
      </v-col>
      <v-col cols="12" md="12">
        <div class=" columnNoticias" style="display:flex">
          <v-col cols="12" v-for="blog in blogHome" :key="blog.titulo" md="4" class="padding0Mob" style="padding: 5px 8px"
            data-aos="fade-up" data-aos-duration="1500">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card class="mx-auto bgcCards justify-center"
                  style="min-height: 480px; max-height: 480px; border-radius:10px">
                  <v-img :src="`https://blogs.abare.cloud/assets/${blog.imagem}`" min-height="295px" max-height="295px"
                    style="align-items: end !important;" class="justify-center align-center">
                    <v-card-title class="fontRoboto colorText WhidtMobile" style="
                    font-family: poppins !important;
                    letter-spacing: -1px;
                    word-break: break-word;
                    color: #fff;
                    background-color:#0b3374cf !important;
                    font-size: 1.5rem !important;
                  ">
                      {{ blog.titulo }}
                      <!-- 
                  {{ blog.titulo.substring(0, 80)
                  }}{{ blog.titulo.length > 19 ? "..." : "" }} -->
                    </v-card-title>

                  </v-img>
                  <div>
                    <v-card-text style="line-height: 1.3 !important" v-html="blog.texto.substring(0, 200) + '...'">
                    </v-card-text>
                  </div>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="#034063">
                      <router-link :to="'/read/' + blog.id">
                        <v-btn color="transparent" style="opacity: 0.8; color: #fff !important; border: solid 3px #fff !important;
                        text-decoration: none !important">Ver mais</v-btn>
                      </router-link>
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </div>
      </v-col>
      <v-row class="align-center justify-center d-flex rowBtn" style="padding-top: 30px; padding-bottom: 30px">
        <v-col cols="12" md="12" class="align-center justify-center d-flex">
          <router-link to="noticias" style="text-decoration: none">
            <v-btn style="background-color:#1B3765; border-radius:20px; color:#fff">Veja mais de nossos blogs!</v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-row>

    <v-dialog v-model="proposito" max-width="600px" style="border-radius:20px">
      <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column" style="background: linear-gradient(148deg, rgba(167,18,19,1) 0%, rgba(223,57,57,1) 100%, rgba(255,255,255,1) 100%);
        max-width:600px; padding:30px; border-radius:20px">
        <h1 style="font-family: Sofia Pro; color:#fff; font-weight:800; font-size:50px">Propósito</h1>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px"> Derrubar as barreiras da
          comunicação através do ensino 4x mais rápido do idioma para pessoas
          que não querem ser tratadas como números e que desejam pertencer à um seleto grupo de realizadores de sonhos.
        </p>
      </v-col>
    </v-dialog>
    <v-dialog v-model="visao" max-width="600px" style="border-radius:20px">
      <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column" style="background: linear-gradient(148deg, rgba(167,18,19,1) 0%, rgba(223,57,57,1) 100%, rgba(255,255,255,1) 100%);
         max-width:600px; padding:30px; border-radius:20px">
        <h1 style="font-family: Sofia Pro; color:#fff; font-weight:800; font-size:50px">Visão</h1>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px"> Treinar e capacitar pessoas com
          base em nosso propósito focando em crescimento e resultado.</p>
      </v-col>
    </v-dialog>
    <v-dialog v-model="valores" max-width="600px" style="border-radius:20px">
      <v-col cols="12" md="12" class="align-center justify-center d-flex flex-column" style="background: linear-gradient(148deg, rgba(167,18,19,1) 0%, rgba(223,57,57,1) 100%, rgba(255,255,255,1) 100%);
        max-width:600px; padding:30px; border-radius:20px">
        <h1 style="font-family: Sofia Pro; color:#fff; font-weight:800; font-size:50px">Valores</h1>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px">
          <span style="font-weight:800">HONESTIDADE, LEALDADE & ÉTICA</span><br>
          * A palavra não faz curva; <br>
          * Você é aquilo que você faz quando ninguém está olhando; <br>
          * Seja responsável e assuma seus erros.
        </p> <br>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px">
          <span style="font-weight:800">COMUNICAÇÃO</span><br>
          * Comunique-se de forma clara, direta e específica; <br>
          * Utilize os canais e materiais acordados;<br>
          * Ouça o próximo verdadeiramente;<br>
          * Esteja presente.
        </p> <br>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px">
          <span style="font-weight:800">MELHORIA CONTÍNUA</span><br>
          * Lifelong learning, o aprendizado nunca acaba;<br>
          * Reconheça as atitudes positivas das pessoas;<br>
          * Dê feedback de forma empática;<br>
          * Tenha humildade para receber feedback e flexibilidade para produzir as mudanças.<br>
        </p> <br>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px">
          <span style="font-weight:800">RESULTADO</span><br>
          * Defina as prioridades;<br>
          * Faça mais com menos;<br>
          * Estabeleça metas, defina os passos, segua o processo e dê o seu melhor;<br>
          * Aja todos os dias como se fosse o day one;<br>
          * Celebre pequenas e grandes conquistas.<br>
        </p><br>
        <p style="font-family: Sofia Pro; color:#fff; font-weight:500; font-size:22px">
          <span style="font-weight:800">COMUNIDADE HUGLERS</span><br>
          * Especialistas em inglês, fluentes em pessoas;<br>
          * Construa credibilidade seguindo os nossos valores;<br>
          * Aja como se a empresa fosse sua;<br>
          * Desperte o senso de pertencimento nos alunos através da atenção aos
          detalhes e atendimento extraordinário.<br>
        </p>
        <br>
      </v-col>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import Flipbook from 'flipbook-vue';
export default {
  components: { Flipbook },
  data: () => ({
    loading: false,

    paginas:
      [null,
        require('../assets/1.png'),
        require('../assets/2s.png'),
        require('../assets/3s.png'),
        require('../assets/4.png'),
        require('../assets/5.png'),
        require('../assets/6.png'),
      ],
    paginaMobile:
      [null,
        require('../assets/1mobileS.png'),
        require('../assets/2mobileS.png'),
        require('../assets/3mobileS.png'),
        require('../assets/4mobileS.png'),
        require('../assets/5mobileS.png'),
        require('../assets/6mobileS.png'),
      ],
    zoom: null,

    snackbar: false,
    loadButton: false,
    proposito: false,
    visao: false,
    valores: false,
    blogHome: [],
    model: 0,
    fotos: [
      {
        src: require('../assets/feedback2.png'),
      },
      {
        src: require('../assets/feedback3.png'),
      },
      {
        src: require('../assets/feedback4.png'),
      },
      {
        src: require('../assets/feedback5.jpeg'),
      },
      {
        src: require('../assets/feedback6.jpeg'),
      },
      {
        src: require('../assets/feedback7.jpeg'),
      },
    ],
    fotosMb: [
      {
        src: require('../assets/feedbackmb1.webp'),
      },
      {
        src: require('../assets/feedbackmb2.webp'),
      },
      {
        src: require('../assets/feedbackmb3.webp'),
      },
      {
        src: require('../assets/feedbackmb4.png'),
      },
    ],
    text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
    contato: { nome: "", email: "", telefone: "", unidade: "" },
    items: ['Caçapava', 'Campinas', 'São José dos Campos - Parque Industrial', 'São José dos Campos - Aquarius', 'São José dos Campos - Vila Industrial',
      'São José dos Campos - Santana', 'São José dos Campos - Vista Verde', 'Taubaté - SP', 'São Sebastião - SP', 'Limeira - SP', 'Ribeirão Preto - SP', 'Piracicaba - SP', 'Nova Serrana - MG', 'São José do Rio Preto - SP', 'Niterói - RJ', 'Vitória - ES',],
    timeout: 2000,
  }),

  created() {
    fetch("https://blogs.abare.cloud/items/hugle?filter[status][_eq]=published&sort=-date_created")
      .then((response) => response.json())
      .then((data) => {
        this.blogHome = data.data;
      });
  },

  methods: {
    enviarFormulario() {
      if ((this.contato.nome === "")) {
        alert("Por favor preencha todos os campos")
        return;
      }
      else if ((this.contato.email === "")) {
        alert("Por favor preencha todos os campos")
        return;
      }
      else if ((this.contato.telefone === "")) {
        alert("Por favor preencha todos os campos")
        return;
      }
      else if ((this.contato.unidade === "")) {
        alert("Por favor preencha todos os campos")
        return;
      }
      else {
        this.loadButton = true,
          this.loading = true,
          fetch("https://gestao.abare.cloud/items/leads", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({ id_formulario: 66, lead: this.contato }),
          })
            .then((response) => response.text())
            .then((data) => {
              window.console.log(data);
              // alert("contato enviado com sucesso");
              this.snackbar = true;
              this.loadButton = false;
            });
      }
    }

  },

  computed: {

    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },
}

</script>

<style scoped>
@media (min-device-width: 340px) and (max-device-width: 731px) {

  .positionMobile {
    position: relative !important;
    right: 0 !important;
    top: initial !important;
    padding: 50px 30px !important;
    width: 360px !important;
  }

  .pdLeft {
    padding-left: 0px !important;
  }

  .marginCards {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .flexMb {
    flex-direction: column !important;
  }

  .gifMobile {
    width: 300px !important
  }

  .pad0mb {
    padding-left: 0px !important
  }

  .paddinPmb {
    padding-left: 0px !important
  }

  .pad0 {
    padding: 0px 0px !important
  }

  .padGarantia {
    padding: 30px 10px 20px 10px !important;
  }

  .wdCarrosselMb {
    width: 400px !important;
  }

  .bgParceria {
    background-image: url("../assets/parceriaMobile.png") !important;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -14px !important;
    background-position: center;
    height: 60vh !important;
  }

  .columnNoticias {
    flex-direction: column !important;
  }

  .bgMobile {
    background-image: url("../assets/header1.png");
    background-repeat: no-repeat !important;
    background-size: cover;
    height: 500px;
    background-attachment: absolute;
    background-color: rgba(255, 255, 255, 0.596);
    background-position: center;
  }

  .padMobile {
    padding: 0px 30px !important;
  }

  .padBlog {
    padding: 15px 10px !important
  }

  .heightAutoMobile {
    height: auto !important;
  }

  .videoCorpo {
    width: 100% !important;
  }

  .flipMobile {
    padding: 65px 0px !important;
  }

  .pad {
    padding: 10px 10px !important;
  }

  .fontSzMobile {
    font-size: 40px !important;
  }



  .fontTextoMeio {
    font-size: 30px !important;
  }
}

.bgParceria {
  background-image: url("../assets/cursosDesk1.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 44px;
  background-position: center;
  height: 90vh;
}

.visoesH {
  background-image: url("../assets/visoesH.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
}

.flipbook {
  width: 85vw;
  padding: 35px 0px 0px 0px;
  height: 80vh;
  margin-top: -30px;
}</style>