<template>
    <v-container fluid>
         <v-row justify="center" align="center">
    </v-row>
        <v-row v-if="intBreakPoint > 2" align="center" style="margin-top:-26px">
      <video-background :src="('https://abaregroup.com/media/homeHugle.mp4')"
        style="max-height: 600px; height: 600px;">
      </video-background>
    </v-row>

          <v-row v-if="intBreakPoint < 2" class="bgMobile" style="margin-top: 12px">
    </v-row>

        <v-row class="fontSzMobile padLeftMobile" data-aos="fade-right" data-aos-duration="1500" 
        style="padding-top:10px; padding-bottom:0px"
            align="center">
            <v-col cols="12" md="1" ></v-col>
            <h1 style="font-family:Sofia Pro, Roboto;
                font-size: 40px;
                margin-bottom:0px;
                color:#1D3E88">Treinamentos</h1>

        </v-row>

        <v-row class="padLeftMobile" data-aos="fade-right" data-aos-duration="1500">
            <v-col cols="12" md="1" ></v-col>
            <p style="
                color:#D31F2C;
                font-size: 18px;
                font-weight:600">Suporte de segunda à sexta em horário comercial</p>

        </v-row>

        <v-row style="padding:0px 0px" align="center" class=""  data-aos="fade-right" data-aos-duration="1500">

            <v-col cols="6" md="3" class="d-flex justify-center align-center flex-column">

                <v-img width="75" src="../assets/admin.webp"></v-img>
                <p style="
              padding-bottom: 0px !important;
              margin-bottom: 0px !important;
              padding-top:17px;
              font-family: sofia pro, Roboto;
              font-weight: 600;
              color: #1D3E88;
              font-size: 18px;
              line-height:;
              text-align: center;
            ">Administração</p>
            </v-col>

            <v-col cols="6" md="3" class="d-flex justify-center align-center  flex-column">
                <v-img width="100" src="../assets/financial.webp"></v-img>
                <p style="
              padding-bottom: 0px !important;
              margin-bottom: 0px !important;
              padding-top: 0px;
              font-family: sofia pro, Roboto;
              font-weight: 600;
              color: #1D3E88;
              font-size: 18px;
             
            ">Financeiro</p>
            </v-col>

            <v-col cols="6" md="3" class="d-flex justify-center align-center flex-column">
                <v-img width="100" src="../assets/comercial.webp"></v-img>
                <p style="
              padding-bottom: 0px !important;
              margin-bottom: 0px !important;
              font-family: sofia pro, Roboto;
              font-weight: 600;
              color: #1D3E88;
              font-size: 18px;
             
            ">Comercial</p>
            </v-col>

            <v-col cols="6" md="3" class="d-flex justify-center align-center flex-column">
                <v-img width="100" src="../assets/marketing.webp"></v-img>
                <p style="
              padding-bottom: 0px !important;
              margin-bottom: 0px !important;
              font-family: sofia pro, Roboto;
              font-weight: 600;
              color: #1D3E88;
              font-size: 18px;
            ">Marketing</p>
            </v-col>
        </v-row>

        <v-row style="padding-top:50px; padding-bottom:50px;" data-aos="fade-right" data-aos-duration="1500">
            <v-col cols="12" md="6" style="padding-left:0px !important">
                <v-img style="border-radius: 0 20px 20px 0px; padding:0px !important; width:100% "
                    src="../assets/imagemDocumento.webp"></v-img>
            </v-col>
            <v-col cols="12" md="1"></v-col>

            <v-col cols="12" md="4" class="d-flex flex-column padMobile padLeftMobile ptMobile"
                style="padding-top:55px;">
                <h1 style="
            font-size:30px;
            line-height:1; 
            color:#1D3E88;
            padding-bottom:20px">Acesso à central do franqueado</h1>
                <p style="font-size:17px; font-weight:600"> Com manuais e modelos de todos os documentos usados
                    pela
                    escola, inclusive artes (marketing).</p>
            </v-col>

            
        </v-row>

        <v-row class="imagemWhatsapp ptMobile" data-aos="fade-up" data-aos-duration="1500">

            <v-col class="d-flex justify-center align-center flex-column" cols="12" md="6">
                <h1 class="padLeftMobile" style="
                 font-size:25px;
                 padding-left:97px;
                 color:#fff;
                 ">Grupo de Whatsapp privativo com os diretores da rede no 1º ano.</h1>
            </v-col>

            <v-col cols="12" md="6" class="d-flex align-center flex-column" style="margin-right:-5px !important">
                <v-img class="sizeImageMobile disableMobile" style="margin-right:58px" width="155"
                    src="../assets/wppIcon.webp"></v-img>
            </v-col>
        </v-row>

        <!-- <v-row style="padding-top:50px; padding-left:50px; padding-right:0px; padding-bottom:50px;"
            class="fundoMobile fundoHugleVermelho padMobile padRow" >
            <v-col cols="12" md="5" data-aos="fade-right" data-aos-duration="1500"
                class="d-flex justify-center align-center flex-column padMobile padLeftMobile marginMobile"
                style="margin-left:50px">
                <h1 class="fontSzMobile" style="
            font-size:48px;
            color:#1D3E88;
            font-weight: 500;
            line-height: 52px;">Possibilidade de compartilhamento<br>de alunos com <br>outras unidades.</h1>
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center flex-column marginImg"
            data-aos="fade-up" data-aos-duration="1500">
                <v-img class="marginImg" style="margin-right:35px" width="500" src="../assets/aluna.webp"></v-img>
            </v-col>
        </v-row> -->

        <!-- <v-row class="fundoHugleVermelho justify-center align-center padMobile" style="padding:0 50px">
            <v-col cols="12" md="12" data-aos="fade-up" data-aos-duration="1500"
                class="justify-center align-center flex-column d-flex fontSzMobile padMobile padLeftMobile" style="
            padding-bottom:20px">
                <h1 style="
                font-family:Sofia pro, Roboto;
                font-size: 40px;
                color:#1D3E88"> Custo mensal padrão da sua empresa</h1>
            </v-col>
            <v-col cols="6" md="2" class="d-flex" data-aos="fade-up" data-aos-duration="1500" >
                <v-card style="min-height: 120px; padding:10px; max-width: 150px;"
                    class="justify-center align-center flex-column d-flex">
                    <p style="
                    font-family:Sofia pro, Roboto;
                    line-height:1;
                    font-size: 20px;
                    font-weight:500;
                    text-align:center;
                    color:#D31F2C;">Taxa de marketing R$250,00 <br> (6 meses)</p>
                </v-card>
            </v-col>
            <v-col cols="6" md="2" class=" d-flex" data-aos="fade-up" data-aos-duration="1500"  style="width:0px">
                <v-card style="min-height: 120px; padding:10px; max-width:150px; width:150px"
                    class="justify-center align-center flex-column d-flex">
                    <p style="
                    font-family:Sofia pro, Roboto;
                    line-height:1;
                    font-size: 20px;
                    text-align:center;
                    font-weight:500;
                    color:#D31F2C;">Funil <br>R$40,00</p>
                </v-card>
            </v-col>
            <v-col cols="6" md="2" class="d-flex" data-aos="fade-up" data-aos-duration="1500"  style="width:0px ">
                <v-card style="min-height: 120px; padding:10px; max-width: 150px; width:150px;"
                    class="justify-center align-center flex-column d-flex">
                    <p style="
                    font-family:Sofia pro, Roboto;
                    line-height:1;
                    font-size: 20px;
                    font-weight:500;
                    text-align:center;
                    color:#D31F2C;">Zoom <br>R$80,00</p>
                </v-card>
            </v-col>
            <v-col cols="6" md="2" class=" d-flex"  data-aos="fade-up" data-aos-duration="1500" style="width:0px">
                <v-card style="min-height: 120px; padding:10px; max-width: 150px;"
                    class="justify-center align-center flex-column d-flex">
                    <p style="
                    font-family:Sofia pro, Roboto;
                    line-height:1;
                    font-size: 20px;
                    font-weight:500;
                    text-align:center;
                    color:#D31F2C;">Contabilidade MEI <br>R$150,00</p>
                </v-card>
            </v-col>
            <v-col cols="6" md="2" class=" d-flex" data-aos="fade-up" data-aos-duration="1500"  style="width:0px">
                <v-card style="min-height: 120px; padding:10px; max-width: 150px;"
                    class="justify-center align-center flex-column d-flex">
                    <p style="
                    font-family:Sofia pro, Roboto;
                    line-height:1;
                    font-size: 20px;
                    font-weight:500;
                    text-align:center;
                    color:#D31F2C;">Gestor de tráfego <br> R$500,00 (sugerido)</p>
                </v-card>
            </v-col>
            <v-col cols="6" md="2" class="  d-flex" data-aos="fade-up" data-aos-duration="1500"  style="width:0px">
                <v-card style="min-height: 120px; padding:10px; max-width: 150px; width:150px;"
                    class="justify-center align-center flex-column d-flex">
                    <p style="
                    font-family:Sofia pro, Roboto;
                    line-height:1;
                    font-size: 20px;
                    font-weight:500;
                    text-align:center;
                    color:#D31F2C;">Tráfego <br> R$ 500,00 <br> (mínimo)</p>
                </v-card>
            </v-col>
        </v-row>

        <v-row class=" d-flex" style=" line-height: 1;"  data-aos="fade-right" data-aos-duration="1500">
            <v-col cols="12" md="6" class="" style="padding:0px !important">
                <v-img class="imgSize" src="../assets/alunos.webp" style="border-radius: 0 20px 20px 0px;
                height: 800px;"></v-img>
            </v-col>

            <v-col cols="12" md="5" class="align-center justify-center padMobile padLeftMobile" style="padding:60px">
                <h1 style="font-family: Sofia Pro, Roboto; font-size:65px; color:#D31F2C; margin-bottom:10px">Opção 1</h1>
                <h2 style="font-family: Sofia Pro, Roboto; font-size:25px; color:#1D3E88; margin-bottom:-9px;">Royalties</h2>
                <div class="d-flex align-center" style="margin-left:-5px !important">
                    <p style="font-family: Sofia Pro, Roboto; font-size:80px; color:#D31F2C; font-weight: 900;">6% </p>
                    <span style="color:#1D3E88; font-size:20px; line-height: 1;
                font-weight: 900;">faturamento<br> bruto</span>
                </div>

                <h1 style="font-family: Sofia Pro, Roboto; font-size:65px; color:#1D3E88; margin-bottom:30px; margin-top:15px;">
                    Opção 2</h1>
                <v-row style="width:100%">
                    <v-col cols="12">
                        <p style="font-family:Sofia Pro, Roboto; font-size:18px; color:#000">
                            <span style="font-weight:600">Responsabilidades da franqueadora</span> – gestão comercial da
                            sua unidade, responsável pelos
                            custos com a
                            equipe de vendedores (salário e comissões) e responsável pelo processo de venda do
                            início ao fim
                            (abordagem do lead ao fechamento da matrícula).
                        </p>
                    </v-col>
                    <v-col cols="12">
                        <p style="font-family:Sofia Pro, Roboto; font-size:18px; color:#000">
                            <span style="font-weight:600"> Investimento:</span> Proporcional ao resultado, ou seja,
                            GANHA-GANHA
                            A venda do material didático será feita pela unidade franqueada (Sem remuneração direta
                            à
                            franqueadora)
                        </p>
                    </v-col>
                    <v-col cols="12">
                        <p style="font-family:Sofia Pro, Roboto; font-size:18px; color:#000">
                            <span style="font-weight:600">A taxa de matrícula será da franqueadora</span> (APENAS as
                            matrículas feitas pelo comercial da
                            franqueadora)
                            A franqueadora investirá em tráfego pago 30% do valor arrecadado com taxas de matrícula.
                            Pagamento de 30% do valor das mensalidades dos alunos matriculados pelo comercial da
                            franqueadora
                            (RECORRENTE)
                        </p>
                    </v-col>
                    <v-col cols="12">
                        <p style="font-family:Sofia Pro, Roboto; font-size:18px; color:#000">
                            <span style="font-weight:600">ROYALTIES</span> – 3% do faturamento bruto
                        </p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row> -->

        <v-row>
            <v-col cols="12" md="6" class="justify-center d-flex flex-column">
                <h1 class="fontSzMobile padLeftMobile" style="font-size:80px; line-height: 1; color:#1D3E88; font-weight:800; padding-left:60px; margin-bottom:15px"  data-aos="fade-right" data-aos-duration="1500">
                    POR QUE <br> INVESTIR <br> EM IDIOMAS?
                </h1>
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon> Apenas <span style="font-weight:800">3%</span> dos brasileiros falam inglês fluentemente <span style="font-weight:800">(IBGE).</span></p>
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon>Até 2027, todas as vagas de emprego vão exigir inglês <span style="font-weight:800">(INFOMONEY).</span></p>
                <p class="padLeftMobile"  style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon>Salários são até <span style="font-weight:800">61%</span> maiores para quem fala inglês <span style="font-weight:800">(CATHO)</span> e <span style="font-weight:800">30%</span> mais altos para quem fala espanhol <span style="font-weight:800">(INFOMONEY).</span></p>
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon>Mais de <span style="font-weight:800">50%</span> dos profissionais já perderam alguma oportunidade por não falar inglês <span style="font-weight:800">(ABRH Brasil).</span></p>
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon>Um dos principais motivos apontados pelos brasileiros para não aprender inglês é a falta de tempo <span style="font-weight:800">(EXAME)</span>, e aqui o nosso
                método é o grande diferencial.</p>
            </v-col>

            <v-col cols="12" md="6" class="align-center justify-center d-flex">
                <v-img class="wdGif" contain style="max-width:450px; width:450px; margin-left:25px" src="../assets/investIdioma.gif"></v-img>
            </v-col>


            <v-col v-if="intBreakPoint > 2" cols="12" md="6" class="justify-center d-flex flex-column">
                <v-img class="wdGif" contain style="max-width:500px; width:500px; margin-left:25px" src="../assets/investFranq.gif"></v-img>
            </v-col>

            <v-col cols="12" md="6" class="justify-center d-flex flex-column" data-aos="fade-down" data-aos-duration="1500">
                <h1 class="fontSzMobile textAlgnL" style="font-size:80px; line-height: 1; color:#1D3E88; font-weight:800; padding-right:60px; margin-bottom:30px; text-align: right;">
                    POR QUE <br> INVESTIR EM <br> FRANQUIA?
                </h1>
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon>
                    Cerca de <span style="font-weight:800">25%</span> das empresas abertas no Brasil são fechadas ainda no primeiro
                    ano de vida, e menos de <span style="font-weight:800">40%</span> dos empreendimentos brasileiros completam 5 anos de mercado.</p>
                <p  class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon> Entretanto <span style="font-weight:800">85%</span> das franquias abertas <span style="font-weight:800">ultrapassam
                a marca de 5 anos de operação.</span></p>
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon> <span style="font-weight:800">27%</span>
                    das escolas de idiomas constituídas independentemente não sobrevivem aos 2 anos iniciais. Apenas <span style="font-weight:800">3%</span> das franquias desse segmento fecham nesse período.</p>
            </v-col>

            <v-col v-if="intBreakPoint < 2" cols="12" md="6" class="justify-center d-flex flex-column">
                <v-img class="wdGif" contain style="max-width:500px; width:500px; margin-left:25px" src="../assets/investFranq.gif"></v-img>
            </v-col>
            
          
           

            <v-col  cols="12" md="6" class="justify-center d-flex flex-column" data-aos="fade-right" data-aos-duration="1500">
                <h1 class="fontSzMobile padLeftMobile" style="font-size:80px; line-height: 1; color:#1D3E88; font-weight:800; padding-left:60px; margin-bottom:30px; text-align: left;">
                    POR QUE INVESTIR<br> NA HUGLE?
                </h1>
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon>
                    Em nosso 1º ano como franqueadora, nossa rede faturou quase <span style="font-weight:800">R$9.200.000,00.</span> Em 2019, nosso crescimento foi de
                    <span style="font-weight:800">30%</span>, ou seja, <span style="font-weight:800">R$ 12.600.000,00.</span></p>
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon> A média de lucratividade
                das nossas escolas é de <span style="font-weight:800">30% </span></p>
                <!-- <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon>
                Cerca de 1/3 das escolas da nossa rede faturam <span style="font-weight:800">mais de R$100.000,00</span> mensais cada.</p> -->
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon>
                Em 2019, foram feitas <span style="font-weight:800">4.000</span> novas matrículas na rede.</p>
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon>
                <span style="font-weight:800">Mais de 1.000 novas matrículas online em 2020,</span> durante a pandemia, após lançarmos nosso curso online.</p>
                <p class="padLeftMobile" style="font-size:17px; font-weight:600; padding-left:60px; color:#042A7C"> <v-icon style="color:#D31F2C">mdi-chevron-right</v-icon>
                <span style="font-weight:800">Hugle Digital:</span> nosso novo modelo de franquias com operação enxuta e remota - sua escola no ponto de equilibrio
                <span style="font-weight:800">em apenas 3 meses.</span></p>
            </v-col>

            <v-col cols="12" md="6" class="align-self-start justify-center d-flex">
                <v-img class="wdGif" contain style="max-width:550px; width:550px; margin-left:25px" src="../assets/investHugle.gif"></v-img>
            </v-col>
        </v-row>

        <v-row class="align-center  " style="padding:30px 0px"  data-aos="fade-up" data-aos-duration="1500">
            <v-col cols="12" md="6"
                class="align-center justify-center d-flex flex-column fontSzMobile padLeftMobile padMobile"
                style="padding-left:60px">
                <h1 class="fontSzMobile" style="font-size:45px; line-height: 1; color:#D31F2C">Ficou interessado?<br>
                    Entre em contato
                    <br>para saber mais
                </h1>
            </v-col>
            <v-col cols="12" md="5" style="padding:0px !important"
                class="d-flex justify-center flex-column align-center">
                <div class="d-flex justify-center flex-column positionMobile" style="background-color: #1e3e87d4;
                 border-radius: 30px;
                 padding: 80px 50px;
                 opacity:;  
                 width: 500px;
                 height: 525px;">

                    <v-text-field background-color="#fff" style="border-radius:20px" outlined v-model="contato.nome"
                        label="Nome" required>
                    </v-text-field>
                    <v-text-field background-color="#fff" style="border-radius:20px" outlined v-model="contato.email"
                        label="E-mail" required>
                    </v-text-field>
                    <v-text-field background-color="#fff" style="border-radius:20px" v-mask="'(##) #####-####'" outlined
                        v-model="contato.telefone" label="Whatsapp" required>
                    </v-text-field>
                    <v-btn :disabled="loading" :load = "loadButton" @click="enviarFormulario" class="mr-4" type="button" height="50px" style="background-color: #fff; color: #1E3E87; 
            font-family: Sofia pro, Roboto; font-weight: 600; font-size:15px; border-radius: 30px;
            text-transform: inherit; margin-top: 25px; margin-right: 0px !important;"> Enviar
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
    </v-container>
</template>


<script>
export default {
    data: () => ({
        snackbar: false,
        loadButton: false,
        loading: false,
        text: "Seu contato foi enviado! Obrigado por sua mensagem, responderemos em breve.",
        contato: { nome: "", email: "", telefone: ""},
        timeout: 2000,
    }),
    
     mounted() {
        window.scrollTo(0, 0);
    },

    methods: {
         enviarFormulario() {
            if ((this.contato.nome === "")) { 
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.email === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else if ((this.contato.telefone === "")){
          alert("Por favor preencha todos os campos")
          return;
      }
      else{
            this.loadButton = true,
            this.loading = true,
      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id_formulario: 72, lead: this.contato }),
      })
        .then((response) => response.text())
        .then((data) => {
          window.console.log(data);
          // alert("contato enviado com sucesso");
          this.snackbar = true;
          this.loadButton = false;
        });
    }
} 
    },

     computed: {

    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },
}
</script>

<style scoped>
@media (min-device-width: 340px) and (max-device-width: 731px) {

    .padMobile {
        padding: 50px 0px !important;
    }

    .wdGif{
        max-width: 300px !important;
        width: 300px !important;
        margin-left: 10px !important;
    }

    .textAlgnL{
        text-align: left !important;
        padding-left: 10px !important;
    }

    .fontSzMobile {
        font-size: 30px !important;
        line-height: 1 !important;
        font-weight: 800 !important;
    }

    .bgMobile {
    background-image: url("../assets/capaFranquiaMobile.webp");
    background-repeat: no-repeat !important;
    background-size: cover;
    height: 500px;
    background-attachment: absolute;
    background-color: rgba(255, 255, 255, 0.596);
    background-position: center;
  }

    .positionMobile {
        position: relative !important;
        right: 0 !important;
        top: initial !important;
        padding: 50px 20px !important;
        width: 360px !important;
    }

    .sizeImageMobile {
        margin-bottom: 60px !important;
        width: 30px !important;
        right: -180px !important;
    }

    .marginImg {
        margin-right: 0px !important;
    }

    .imgSize {
        height: 250px !important;
    }

    .fundoHugleVermelho {
        background-image: url("../assets/capahugle.webp") !important;
        background-repeat: no-repeat !important;
        background-size: cover;
        background-attachment: fixed;
        background-position: center-top;
    }

    .padLeftMobile {
        padding-left: 10px !important;
    }

    .ptMobile {
        padding-top: 0px !important;
    }

    .marginMobile {
        margin-left: 0px !important;
    }

    .textoTopo {
        font-size: 55px !important;
        opacity: 0.5 !important;
        line-height: 49px !important;
        padding: 3px 3px !important;
    }
}

.backgroundTopo {
    background-image: url("../assets/capaFranquia.webp");
    background-repeat: no-repeat !important;
    background-size: cover;
    height: 580px;
    background-attachment: fixed;
    background-color: rgba(255, 255, 255, 0.596);
    background-position: center-top;
}

.imagemWhatsapp {
    background-image: url("../assets/fundoWpp.webp");
    background-repeat: no-repeat !important;
    background-size: cover;
    height: 180px;
    background-attachment: fixed;
    background-color: rgba(255, 255, 255, 0.596);
    background-position: center-top;
}

</style>